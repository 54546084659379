import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import {
    Layout, LeadProcessExplainer, PopularLocations, CtaBanner,
} from '../components';
import HomePageStyles from './styles/index.module.scss';

// eslint-disable-next-line arrow-body-style
const HomePageBlogColumns = loadable(() => import('../components/homePageBlogColumns/homePageBlogColumns'));


const HomePage = ({ data, location }) => {
    const homePage = _.head(data.graphCMS.homePages) || {};
    const { states } = data.graphCMS;
    const metaTitle = _.get(homePage, 'metaTitle') || 'WikiLawn | Lawn Care Advice for your Area | Outdoor Services';
    const metaDescription = _.get(homePage, 'metaDescription') || 'Lawn Care Advice for your Area | Outdoor Services';
    const { homePageContainer, blogSectionHeader, footerCtaWrapper } = HomePageStyles;

    const leadCollectionProps = {
        headline: _.get(homePage, 'heroHeadline') || 'Best Lawn Care Services from Top Pros',
        subHeadline: _.get(homePage, 'heroSubHeadline') || 'Get free cost estimates from professionals for lawn care and landscaping projects.',
    };

    const links = [
        {
            to: '/blog/',
            navText: 'Blog',
        },
        {
            to: '/lawn-care/',
            navText: 'Lawn Care',
        },
        {
            to: '/about/',
            navText: 'About Us',
        },

        {
            to: '/contact/',
            navText: 'Contact Us',
        },
    ];

    return (
        <Layout
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            leadCollectionProps={leadCollectionProps}
            states={states}
            isHomePage={Boolean(location.pathname === '/')}
            links={links}
        >
            <div className={homePageContainer} data-testid="home-page">
                <LeadProcessExplainer />
                <div>
                    <h2 data-testid="blog-title" className={blogSectionHeader}>
                        {homePage.blogSectionTitle || 'Everything You Need to Know About Lawn Care'}
                    </h2>
                    <HomePageBlogColumns blogColumns={homePage.homePageBlogColumns} />
                </div>
                <div>
                    <CtaBanner heading="Get FREE Quotes from up to 4 Professionals" subHeading="Enter details of your project to get no-obligation quotes from pros." />
                </div>
                <div>
                    <PopularLocations />
                </div>
            </div>
            <div className={footerCtaWrapper}>
                <CtaBanner heading="Get FREE Quotes from up to 4 Professionals" subHeading="Enter details to get free estimates from the best local professionals near you." />
            </div>
        </Layout>
    );
};

export const pageQuery = graphql`
    query HomePagePageQuery {
        graphCMS {
            homePages(last: 1) {
                id
                heroHeadline
                heroSubHeadline
                blogSectionTitle
                homePageBlogColumns {
                    id
                    headerText
                    headerSubText
                    headerLink
                    homePageBlogLinks {
                        id
                        path
                        title
                    }
                    image {
                        url
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 920, quality: 75) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
                metaTitle
                metaDescription
            }
            states(orderBy: name_ASC) {
                id
                slug
            }
        }
    }
`;

HomePage.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            homePages: PropTypes.arrayOf(
                PropTypes.shape({
                    metaTitle: PropTypes.string,
                    metaDescription: PropTypes.string,
                    homePageBlogColumns: PropTypes.arrayOf(PropTypes.shape({})),
                }),
            ),
            states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        }),
    }),
};

HomePage.defaultProps = {
    data: null,
};

export default HomePage;
